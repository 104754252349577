import { logisticsService } from '@/api';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { DeliveryModeEnum, LogisticsOrderStatusEnum, LogisticsStatusEnum } from '@/resource/enum';
import { LogisticsDetail, LogisticsProductList, LogisticsTrack } from '@/resource/model';
import { messageError, messageErrors, showWarningConfirm, translation } from '@/utils';
import { getLodop } from '@/utils/lodop';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import EditLogisticInfo from '../edit-logistic-info/edit-logistic-info.vue';
import ElectronicBillDelivery from '../electronic-bill-delivery/electronic-bill-delivery.vue';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'LogisticsDetails',
  components: { EditLogisticInfo, ElectronicBillDelivery }
})
export default class LogisticsDetails extends mixins(PagingMixin) {
  public logistics: LogisticsDetail | null = null;

  public id = Number(this.$route.query.id);

  /**
   * 控制批量修改物流收发货地址的dialog显隐
   */
  public editLogisticInfoVisible = false;

  /**
   * 电子面单下单dialog的显隐
   */
  public takeElectronicOrderVisible = false;

  public submitLoading = false;

  public mapMode = false;

  public routeInfo: {
    no: string;
    from: string;
    to: string;
    status: number;
    arrivalTime: string;
    trailUrl: string;
  } | null = null;

  public timeline: Array<any> = [];

  public tableOption: OsTableOption<LogisticsProductList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<LogisticsProductList>> = [
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'projectProduct.deliveryNumber',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as LogisticsProductList).finishWidth} × ${(row as LogisticsProductList).finishHeight}`;
      }
    }
  ];

  private needReload = false;

  public get deliveryMode(): string {
    return translation(`deliveryMode.${DeliveryModeEnum[this.logistics!.sendMethod]}`);
  }
  /**
   * 单据状态
   */
  public get statusName(): string {
    if (!LogisticsOrderStatusEnum[this.logistics!.status]) {
      return translation('common.unKnownStatus');
    }
    return translation(`logisticsOrderStatus.${LogisticsOrderStatusEnum[this.logistics!.status]}`);
  }
  /**
   * 物流状态
   */
  public get logisticsStatusName(): string {
    if (!LogisticsStatusEnum[this.logistics!.logisticsStatus]) {
      return translation('common.unKnownStatus');
    }
    return translation(`logisticsStatus.${LogisticsStatusEnum[this.logistics!.logisticsStatus]}`);
  }
  /**
   * 控制展示关闭原因
   */
  public get isShowCloseReason(): boolean {
    if (LogisticsOrderStatusEnum.closed === this.logistics?.status) {
      return true;
    }
    return false;
  }

  /**
   * 是否第三方物流发货
   */
  public get isTPL(): boolean {
    return this.logistics?.sendMethod === DeliveryModeEnum.TPL;
  }

  /**
   * 是否允许打印电子面单
   */
  public get allowPrint(): boolean {
    return this.isTPL && this.logistics?.status === LogisticsOrderStatusEnum.inTransit;
  }

  /**
   * 物流单是否已发货
   */
  public get isDelivered(): boolean {
    return this.logistics?.status !== LogisticsOrderStatusEnum.new;
  }

  /**
   * 是否允许修改收发货信息
   */
  public get isAllowEditLogisticInfo(): boolean {
    return this.logistics?.status === LogisticsOrderStatusEnum.new;
  }

  /**
   * 物流状态是否完成
   */
  public get isComplete(): boolean {
    return (
      this.logistics?.status === LogisticsOrderStatusEnum.closed ||
      this.logistics?.status === LogisticsOrderStatusEnum.received
    );
  }

  /**
   * 发货地址
   */
  public get senderAddress(): string {
    if (this.logistics?.sendProvince) {
      return `
        ${this.logistics.sendProvince ?? ''}
        ${this.logistics.sendCity ?? ''}
        ${this.logistics.sendDistrict ?? ''}
        ${this.logistics.senderAddress ?? ''}
      `;
    }
    return '';
  }

  /**
   * 收货地址
   */
  public get receivingAddress(): string {
    if (this.logistics?.receivingProvince) {
      return `
        ${this.logistics.receivingProvince ?? ''}
        ${this.logistics.receivingCity ?? ''}
        ${this.logistics.receivingDistrict ?? ''}
        ${this.logistics.receivingAddress ?? ''}
        `;
    }
    return '';
  }

  public get showLogisticTrack(): boolean {
    return this.isDelivered && this.isTPL;
  }

  public activated(): void {
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.loadDetails();
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.loadDetails();
    }
  }

  public openEditLogisticsInfo(): void {
    this.editLogisticInfoVisible = true;
  }

  public openTakeElectronicOrderDialog(): void {
    this.takeElectronicOrderVisible = true;
  }

  public delivery(): void {
    showWarningConfirm(translation('logistics.confirmDelivery'))
      .then(async () => {
        this.submitLoading = true;
        try {
          await logisticsService.batchDelivery([this.logistics!.id]);
          this.needReload = true;
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        } finally {
          this.submitLoading = false;
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  public async print(): Promise<void> {
    try {
      const lodop = getLodop();
      const res = await logisticsService.batchPrint([this.logistics!.id]);
      res.forEach(async x => {
        lodop?.PRINT_INIT('电子面单');
        lodop?.SET_PRINTER_INDEX(x.printName);
        lodop?.SET_PRINT_PAGESIZE(1, `${x.width}mm`, `${x.height}mm`, '');
        lodop?.ADD_PRINT_IMAGE(0, 0, `${x.width}mm`, `${x.height}mm`, `<img src="${x.orderContent}" />`);
        // (不可变形)扩展缩放模式
        lodop?.SET_PRINT_STYLEA(0, 'Stretch', 2);
        // lodop?.PREVIEW();
        lodop?.PRINT();
      });
      this.needReload = true;
    } catch (error) {
      messageError(error);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/logistics') {
      to.meta!.needReload = this.needReload;
    }
    next();
  }

  public getLogisticsProducts(): void {
    this.tableOption.loading = true;
    logisticsService
      .getLogisticsProducts(this.id, this.paging)
      .then(res => {
        this.totalData = res.total;
        this.tableOption.data = res.data || [];
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getLogisticsInfo(): void {
    logisticsService
      .getById(this.id)
      .then(res => {
        this.handelInfoData(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  private initLogisticsTrack(logisticsTrack?: LogisticsTrack): void {
    if (!logisticsTrack || !logisticsTrack.data) {
      return;
    }
    this.timeline = logisticsTrack.data.map(x => {
      return {
        content: x.context,
        timestamp: x.time,
        size: 'normal',
        type: 'primary',
        placement: 'top'
      };
    });
    this.routeInfo = {
      no: logisticsTrack.nu,
      from: logisticsTrack.routeInfo.from.name,
      to: logisticsTrack.routeInfo.to.name,
      arrivalTime: logisticsTrack.arrivalTime,
      trailUrl: logisticsTrack.trailUrl,
      status: Number(logisticsTrack.state)
    };
  }

  /**
   * 更新物流信息
   */
  private updateLogistics(): void {
    logisticsService
      .refreshTrack(this.id)
      .then(res => {
        if (res) {
          this.handelInfoData(res);
          console.log(res);
        } else {
          Message.info(translation('logistics.noLogisticsTrack'));
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private handelInfoData(res: LogisticsDetail): void {
    res.price = (res.price?.toFixed(2) as any) as number;
    this.logistics = res;
    this.resetMapData();
    const logisticsTrack: LogisticsTrack = JSON.parse(res.logisticsTrack);
    // 如果是已发货且是第三方物流发货，加载物流轨迹信息
    if (this.isDelivered && this.isTPL) {
      this.initLogisticsTrack(logisticsTrack);
    }
  }
  private resetMapData(): void {
    this.mapMode = false;
    this.timeline = [];
    this.routeInfo = null;
  }

  private loadDetails(): void {
    this.getLogisticsInfo();
    this.getLogisticsProducts();
  }
}
